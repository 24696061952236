<template>
    <div class="table-responsive">
      <div>
        <vs-select
          v-if="paginateTop"
          class="selectExample izquierda"
          v-model="interPaginate"
          >
          <vs-select-item v-for="(page,index) in perPage" :key="index" :value="page" :text="page"/>
        </vs-select>
        <vs-input
          v-if="searchable"
          icon-after="true"
          :icon="searchIcon"
          class="text-muted derecha"
          :placeholder="searchPlaceholder"
          v-model="search"
        />
      </div>
      <table class="table v-middle border">
        <thead>
          <tr class>
            <th class="border-top-0" :class="{'cursor-pointer': column.orderable}" v-for="(column, index) in columns" :key="index" @click="column.orderable ? cambiarSentido(index) : ''">
              {{column.name}}
              <vs-icon v-if="order.column == index" :icon="`keyboard_arrow_${order.type ? 'up' : 'down'}`" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(fila, index) in datos" :key="index">
            <td v-for="(column, indx) in columns" :key="indx" v-html="column.template ?
              column.template.replaceAll('TPLData', column.reference.includes('.') ? fila[column.reference.split('.')[0]][column.reference.split('.')[1]] : fila[column.reference]) :
              column.date ?
                formatDate(column.dateFormat ? column.dateFormat : 'd/m/Y H:i:s', fila[column.dbName]) :
                swipe(indx, column.dbName.includes('.') ? fila[column.dbName.split('.')[0]][column.dbName.split('.')[1]] : fila[column.dbName])">
            </td>
          </tr>
        </tbody>
        <tfoot v-if="tableFooter">
          <tr class>
            <th class="border-top-0" :class="{'cursor-pointer': column.orderable}" v-for="(column, index) in columns" :key="index" @click="column.orderable ? cambiarSentido(index) : ''">
              {{column.name}}
              <vs-icon v-if="order.column == index" :icon="`keyboard_arrow_${order.type ? 'up' : 'down'}`" />
            </th>
          </tr>
        </tfoot>
      </table>
      <div>
        <vs-select
          v-if="paginateBottom"
          class="selectExample izquierda"
          v-model="paginate"
          >
          <vs-select-item v-for="(page,index) in perPage" :key="index" :value="page" :text="page"/>
        </vs-select>
        <vs-pagination
          v-if="pages"
          :total="lastPage"
          v-model="page"
          class="derecha"
          prev-icon="arrow_back"
          next-icon="arrow_forward"
          :max="maxPaginate"
        />
      </div>
    </div>
  </template>

  <script>
  import axios from 'axios'

  export default {
    name: "VityTable",
    props: {
      columns: {
        type: Array,
        required: true
      },
      url: {
        type: String,
        required: true
      },
      orderBy:{
        type: Object,
        default: function(){
          return{
            column: 0,
            type: 0
          }
        }
      },
      paginate: {
        type: Number,
        default: 10
      },
      perPage:{
        type: Array,
        default: function(){
          return [5, 10, 25, 50, 100];
        }
      },
      params:{
        type: Object,
        default: function(){
          return {};
        }
      },
      swipeResult:{
        type: Object,
        default: function(){
          return null;
        }
      },
      searchable:{
        type: Boolean,
        default: true
      },
      searchPlaceholder:{
        type: String,
        default: 'Buscar...'
      },
      searchIcon:{
        type: String,
        default: 'search'
      },
      paginateTop:{
        type: Boolean,
        default: true
      },
      paginateBottom:{
        type: Boolean,
        default: false
      },
      tableFooter:{
        type: Boolean,
        default: false
      },
      pages:{
        type: Boolean,
        default: true
      },
      maxPaginate:{
        type: Number,
        default: 9
      },
      onCatch:{
        type: Function,
        default: error => {
          alert(error);
        }
      },
      paramsSendOrderColumn:{
        type: String,
        default: 'orderBy[column]'
      },
      paramsSendOrderOrder:{
        type: String,
        default: 'orderBy[order]'
      },
      paramsSendOrderOrderDesc:{
        type: String,
        default: 'desc'
      },
      paramsSendOrderOrderAsc:{
        type: String,
        default: 'asc'
      },
      paramsSendSearch:{
        type: String,
        default: 'search'
      },
      paramsSendPaginate:{
        type: String,
        default: 'paginate'
      },
      paramsSendPage:{
        type: String,
        default: 'page'
      },
      paramsSendColumns:{
        type: String,
        default: 'columns'
      },
      paramsRequestLastPage:{
        type: String,
        default: 'lastPage'
      },
      paramsRequestTotal:{
        type: String,
        default: 'total'
      },
      paramsRequestState:{
        type: String,
        default: 'state'
      },
      paramsRequestStateOk:{
        type: String,
        default: 'OK'
      },
      paramsRequestData:{
        type: String,
        default: 'data'
      },
      paramsRequestMsg:{
        type: String,
        default: 'msg'
      },
    },
    data: function(){
      return{
        order: this.orderBy,
        datos: [],
        search: null,
        page: 1,
        lastPage: 0,
        total: 0,
        interPaginate: this.paginate
      }
    },
    methods: {
      cambiarSentido: function(index){
        this.order.column = index;
        this.order.type = this.order.type ? 0 : 1;
        this.getDatos();
      },
      getDatos: function(){
        let params = Object.assign({}, this.params);
        params[this.paramsSendOrderColumn] = this.columns[this.order.column].dbName;
        params[this.paramsSendOrderOrder] = this.order.type ? this.paramsSendOrderOrderDesc : this.paramsSendOrderOrderAsc;
        params[this.paramsSendSearch] = this.search;
        params[this.paramsSendPaginate] = this.interPaginate;
        params[this.paramsSendPage] = this.page;
        params[this.paramsSendColumns] = this.columns;

        axios
          .get(this.url, {
            params: params
          })
          .then(response => {
            let rs = response.data;
            if(rs[this.paramsRequestState] == this.paramsRequestStateOk){
              this.datos = rs[this.paramsRequestData];
              if(this.datos.length > 0){
                this.lastPage = this.datos[0][this.paramsRequestLastPage];
                this.total = this.datos[0][this.paramsRequestTotal];
              }
            }
            else
              alert(rs[this.paramsRequestMsg]);
          })
          .catch(this.onCatch)
      },
      formatDate: function(format, fecha, actual){
        var date;
        if(fecha)
          date = new Date(fecha);
        else if(actual)
          date = new Date();

        //FECHA
        format = format.replaceAll(/\bd\b/g, String(date.getDate()).padStart(2, '0'));
        format = format.replaceAll(/\bj\b/g, String(date.getDate()));
        format = format.replaceAll(/\bn\b/g, String(date.getMonth()+1));
        format = format.replaceAll(/\bm\b/g, String(date.getMonth()+1).padStart(2, '0'));
        format = format.replaceAll(/\bY\b/g, String(date.getFullYear()));
        //HORA
        format = format.replaceAll(/\bH\b/g, String(date.getHours()));
        format = format.replaceAll(/\bh\b/g, String(date.getHours() >= 12 ? date.getHours() - 12 : date.getHours()));
        format = format.replaceAll(/\bi\b/g, String(date.getMinutes()).padStart(2, '0'));
        format = format.replaceAll(/\bs\b/g, String(date.getSeconds()).padStart(2, '0'));

        return format;
        //return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();//YYYY-MM-dd
        //return String(date.getDate()).padStart(2, '0')+'/'+String(date.getMonth()+1).padStart(2, '0')+'/'+date.getFullYear();//dd-MM-YYYY
      },
      swipe: function(y, x){
        var res = x;
        if(this.swipeResult){
          if(this.swipeResult.column.includes(y)){
            res = this.swipeResult.data[x];
          }
        }
        return res;
      }
    },
    watch:{
      search: function(newval, oldval){
        this.page = 1;
        this.getDatos();
      },
      paginate: function(newval, oldval){
        this.interPaginate = this.paginate;
      },
      page: function(newval, oldval){
        this.getDatos();
      },
      url: function(newval, oldval){
        this.getDatos();
      },
      interPaginate: function(newval, oldval){
        this.page = 1;
        this.getDatos();
      }
    },
    created: function(){
      this.getDatos();
    }
  };
  </script>

  <style>
    .izquierda{float:left}
    .derecha{float:right}
  </style>